import React, {useState} from 'react';
import {NavLink} from 'react-router-dom'
import logo from '../../img/KeyGenLogo.png'
import {CloseOutlined, MenuOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";

const Header =()=>{
    const [mobileNavOpen, setMobileNavOpen] = useState(false);
    const navigate = useNavigate()
    const toggleMobileNav = () => {
        setMobileNavOpen(!mobileNavOpen);
    };


    return(
        <>
            <header className={"navbar navbar-expand-sm flex-md-row bd-navbar"}>


                      <img onClick={()=>navigate(`/`)} className={"navbar-brand"} src={logo} alt={'logo'}/>
                      <div className={'navbar-nav-scroll'}>
                          <ul className={'nav justify-content-end'}>
                              <li className={'nav-item'}>
                                  <NavLink to={"/"} className={'menu__link'} exact={true}>Home</NavLink>
                              </li>
                          </ul>
                      </div>

                      <div className={'navbar-nav-scroll'}>
                          <ul className={'nav justify-content-end'}>
                              <li className={'nav-item'}>
                                  <NavLink to={"/about-us"} className={'menu__link'} exact={true}>About Us</NavLink>
                              </li>
                          </ul>
                      </div>

                      <div className={'navbar-nav-scroll'}>
                          <ul className={'nav justify-content-end'}>
                              <li className={'nav-item'}>
                                  <NavLink to={"/company"} className={'menu__link'} exact={true}>Company</NavLink>
                              </li>
                          </ul>
                      </div>

                      <div className={'navbar-nav-scroll'}>
                          <ul className={'nav justify-content-end'}>
                              <li className={'nav-item'}>
                                  <NavLink to={"/blog"} className={'menu__link'} exact={true}>Blog</NavLink>
                              </li>
                          </ul>
                      </div>
                <div className={'navbar-nav-scroll'}>
                    <ul className={'nav justify-content-end'}>
                        <li className={'nav-item'}>
                            <NavLink to={"/contact-us"} className={'menu__link'} exact={true}>Contact Us</NavLink>
                        </li>
                    </ul>
                </div>



                <div className={'header-mobile'}>

                        <div style={{paddingRight:'2vw'}} onClick={()=> toggleMobileNav()}>
                            {mobileNavOpen ? <CloseOutlined style={{ fontSize: '30px', color: 'white' }} /> : <MenuOutlined style={{ fontSize: '30px', color: 'white' }} />}
                        </div>

                    {mobileNavOpen && (
                        <nav className="mobile-nav">
                            <img  className={"mobile-nav-img"} src={logo} alt={'logo'}/>
                            <ul>


                                <li><NavLink onClick={()=>setMobileNavOpen(!mobileNavOpen)} to={"/"} className={'menu__link'} exact={true}>Home</NavLink></li>
                                <li><NavLink onClick={()=>setMobileNavOpen(!mobileNavOpen)} to={"/about-us"} className={'menu__link'} exact={true}>About Us</NavLink></li>
                                <li><NavLink onClick={()=>setMobileNavOpen(!mobileNavOpen)} to={"/company"} className={'menu__link'} exact={true}>Company</NavLink></li>
                                <li><NavLink onClick={()=>setMobileNavOpen(!mobileNavOpen)} to={"/blog"} className={'menu__link'} exact={true}>Blog</NavLink></li>
                                <li><NavLink onClick={()=>setMobileNavOpen(!mobileNavOpen)} to={"/contact-us"} className={'menu__link'} exact={true}>Contact Us</NavLink></li>
                            </ul>
                        </nav>
                    )}
                </div>




            </header>
        </>
    );



}
export default Header;
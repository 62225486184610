import React from 'react';
import {NavLink} from "react-router-dom";

const Footer=()=>{


    return(
        <footer className="footer">
            <div className="waves">
                <div className="wave" id="wave1"></div>
                <div className="wave" id="wave2"></div>
                <div className="wave" id="wave3"></div>
                <div className="wave" id="wave4"></div>
            </div>

            <ul className="menu">
                <li className="menu__item"><NavLink to={"/"} className={"menu__link"} exact={true}>Home</NavLink></li>
                <li className="menu__item"><NavLink to={"/company"} className={'menu__link'} exact={true}>Company</NavLink></li>
                <li className="menu__item"><NavLink to={"/blog"} className={'menu__link'} exact={true}>Blog</NavLink></li>
                <li className="menu__item"><NavLink to={"/about-us"} className={'menu__link'} exact={true}>About Us</NavLink></li>
                <li className="menu__item"><NavLink to={"/contact-us"} className={'menu__link'} exact={true}>Contact Us</NavLink></li>

                <li className="menu__item"><NavLink to={"/privacy-policy"} className={'menu__link'} exact={true}>Privacy-Policy</NavLink></li>




            </ul>
            <p style={{opacity: "0.75"}}>2022 KeyGen</p>

        </footer>

    )

}
export default Footer;
import React, {useState} from 'react';
import {Route, Routes, BrowserRouter} from "react-router-dom";
import "./styles/styles.css"
import "bootstrap/dist/css/bootstrap.min.css"
import Header from "./components/nav/Header";
import KeywordGenMain from "./components/generators/KeywordGenMain";
import CompanySearch from "./components/generators/CompanySearch";
import BlogMain from "./components/blog/BlogMain";
import BlogPost from "./components/blog/BlogPost";
import PrivacyPolicy from "./components/ad/PrivacyPolicy";
import Footer from "./components/nav/Footer";
import About from "./components/other/About";

import Amplify from '@aws-amplify/core'
import config from '../src/aws-exports'
import Contact from "./components/other/Contact";
Amplify.configure(config)

function App() {
    return (

        <BrowserRouter>
            <Header/>
            <div className={'container-fluid'}>


                <Routes>
                    <Route path={"/contact-us"} element={<Contact/>}/>
                    <Route path={"/company"} element={<CompanySearch/>}/>
                    <Route path={"/blog"} element={<BlogMain/>}/>
                    <Route path={"/blog/:id"} element={<BlogPost/>}/>
                    <Route path={"/privacy-policy"} element={<PrivacyPolicy/>}/>
                    <Route path={"/about-us"} element={<About/>}/>
                    <Route path={"/"} element={<KeywordGenMain/>}/>

                </Routes>

            </div>
        <Footer/>


        </BrowserRouter>

    );
}

export default App;

import React from 'react';

export default class

    extends React.Component {
    componentDidMount () {

        const installGoogleAds = () => {
            const elem = document.createElement("script");
            elem.src =
                "//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
            elem.async = true;
            elem.defer = true;
            document.body.insertBefore(elem, document.body.firstChild);
        };
        installGoogleAds();
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    }


    render () {
        return (
            <div className='ad'>
                <ins className='adsbygoogle'
                     style={{ display: 'block' }}
                     data-ad-client='ca-pub-4429745364877859'
                     data-ad-slot='6642539577'
                     data-ad-format='auto' />
            </div>
        );
    }
}
import React from 'react';
import seo1 from "../../img/seo-1.jpg";

const About =()=>{


    return(
        <>



            <div className={'container'}>
                <div style={{textAlign: 'center', marginTop: '10vh', marginBottom: '2vh'}} className={'row'}>
                    <div  className={'container-lg'}>
                        <div className={'row'}>
                            <div  className={'col'}>

                                <div className={'card-text-lg'}>About us </div><br/>

                                <div className={'container'} style={{textAlign: 'left'}}>

                                    Welcome to KeyGen! We are a team of SEO experts and enthusiasts who are dedicated to helping businesses and individuals improve their search engine optimization (SEO) efforts.
                                    <br/><br/>
                                    We understand that SEO can be complex and time-consuming, especially for those who are new to the field. That's why we have developed a simple, yet powerful tool that helps users generate high-quality keywords for their website and content.
                                    <br/><br/>
                                    Our keyword generator uses advanced algorithms and natural language processing to identify the most relevant and effective keywords for any given topic or industry. By using our tool, you can easily and quickly generate a list of keywords that will help improve your search engine ranking and drive more traffic to your website.
                                    <br/><br/>
                                    In addition to our keyword generator, we plan to  offer a range of other SEO tools and resources, including an SEO analyzer, a backlink checker, and an SEO score calculator in the future. Our goal is to provide you with all the tools and information you need to improve your SEO and reach your online marketing goals.
                                    <br/><br/>
                                    Thank you for choosing KeyGen. We hope our tool helps you improve your SEO and drive more traffic and revenue to your website. Please don't hesitate to contact us if you have any questions or suggestions. We are always happy to help.

                                    <br/></div>


                            </div>

                        </div>
                        <div className={'row'}>
                            <div style={{maxHeight:'100%'}} className={'col display-none'}>
                                <br/>
                                <img className={'img-fluid'} src={seo1} alt={'seo'}/>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div style={{marginTop:'20%'}} className={'container'}>
                <div className={'card-holder'}>
                    <div className={'card bg-gold'}>
                        <span className={'card-text-lg'}> Open AI Powered</span><br/>
                        <span>Keywords and Blogs are generated by AI</span><br/>
                    </div>
                </div>

    </div>


    </>
    )
}
export default About
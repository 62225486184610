import React, {useState} from 'react';
import {config} from "../../openAIConfig/config";
import SearchResults from "./SearchResults";
import {Configuration, OpenAIApi} from "openai";
import {Button} from "antd";
import Ad from "../ad/Ad";
import logo from "../../img/KeyGenLogo.png";


const CompanySearch =()=>{
    const [companyName, setCompanyName]= useState('');
    const [state, setState]= useState('');
    const [city,setCity]= useState('');
    const [loading, setLoading] = useState(false)
    const [keywords, setKeywords] = useState(null)
    const configuration = new Configuration({
        apiKey: config.apikey,
    });
    const openai = new OpenAIApi(configuration);

    const responseParser =(res)=>{
        let arr = res.split('\n')
        arr.splice(0,2);
        return arr;

    }


    const getKeywords = async (event) => {
        setLoading(true)
        event.preventDefault();
        let prompt;

            prompt= `Best Google Seo Keywords for ${companyName} in ${city}, ${state}`


        let response = await openai.createCompletion({
            model: "text-davinci-003",
            prompt: prompt,
            temperature: 0.7,
            max_tokens: 256,
            top_p: 1,
            frequency_penalty: 0,
            presence_penalty: 0,
        });


        if(response){
            let res =responseParser(response.data.choices[0].text)
            console.log(res)
            setKeywords(res)
            setLoading(false)
        }


    }



    return(

    <>


            <div style={{paddingTop:"10%"}} className={'card-holder'}>
                <div className={'card bg-gold'}>
                    <div className={'row'}>
                        <div className={'col'}>
                            <div className={'card-text-lg'}> Our Company Keyword Generator </div><br/> <br/>
                            <div className={'card-text-sm'}>
                                Enter in your Company Name, City, and State to get some keywords<br/>
                                Our Generator will try to find your business and do all the hard thinking for you!
                            </div>


                        </div>

                    </div>


                </div>
            </div>

        <div className={'container search-form'}>

               <div className={'container-lg'}>


                   <div className={'row'}>
                       <div className={'col'}>
                           <label>Company Name</label>
                           <input className={'form-control'} value={companyName} onChange={(e)=>setCompanyName(e.target.value)} required/>
                       </div>

                       <div className={'col'}>
                           <label>State</label>
                           <select  value={state} className={'form-control'} onChange={(e)=>setState(e.target.value)} required>
                               <option> choose.... </option>
                               {config.states.map(state=>(
                                   <option value={state.code}>{state.name}</option>
                               ))}
                           </select>
                       </div>
                       <div className={'col'}>
                           <label>City</label>
                           <input className={'form-control'} value={city} onChange={(e)=>setCity(e.target.value)} required/>
                       </div>

                   </div>
                   <div style={{paddingTop: '5%'}} className={'row'}>
                       <div className={'col'}></div>
                       <div style={{textAlign: 'center'}} className={'col'}>
                           <Button type="primary" loading={loading} onClick={(e)=>(getKeywords(e))}>
                               Get Keywords!
                           </Button>
                       </div>
                       <div className={'col'}></div>
                   </div>


                   <div style={{marginTop:'1%'}} className={'row'}>
                       {<SearchResults results={keywords? keywords: null}/>}
                   </div>

               </div>
                      </div>
           <div className={'row'}>
               {/*<Ad/>*/}
           </div>
       </>
    )




}
export default CompanySearch
import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import blogs from "./../../json/blog1.json"

const BlogPost =()=>{
    const {id} = useParams();
    const [post, setPost] = useState(null)
    const [text, setText] = useState(null)
    useEffect(()=>{

        if(!post){
            blogs.map(blog=>{
                blog.blogs.filter(item=> {
                    console.log(item.id)
                    if(parseInt(item.id) === parseInt(id)){
                        item.id = id
                        setPost(item)
                        let tmpText=responseParser(item.post)
                        console.log(tmpText)
                        setText(tmpText)
                    }
                })


            })
        }
    },[])

    useEffect(()=>{

    },[])

    const responseParser =(res)=>{
        let arr = res.split('\n')
        arr.splice(0,2);
        return arr;

    }

    console.log(post)

    return(
        <div>
            {post?
                <div className={'container'}>
                    <div className={'card-holder'}>
                        <div className={'card bg-gold'}>
                            {post.prompt}<br/>
                            <span>read below...</span><br/>
                        </div>
                    </div>
                    <div style={{height: '30vh'}} className={'row'}>

                    </div>
                    <div className={'row'}>
                        <div>{text ?
                        text.map(paragraph=>(
                            <>
                                <span>{paragraph}</span>
                                <br/>
                            </>
                        )):''
                        }</div>
                    </div>
                </div> :'loading post...'
            }

        </div>
    );

}
export default BlogPost;
import React , {useState, useEffect} from 'react';
import CompanyForm from "./CompanyForm";
import { Tooltip } from 'antd';
import PersonalForm from "./PersonalForm";
import AdSense from 'react-adsense';
import logo from "../../img/KeyGenLogo.png";
import Ad from "../ad/Ad";
import {useNavigate} from "react-router-dom";
import seo1 from '../../img/seo-1.jpg'
import seo2 from '../../img/seo-2.jpg'
import seo3 from '../../img/seo-3.png'
import seo4 from '../../img/seo4.jpg'
import seo5 from '../../img//keywordsImg.jpg'
const KeywordGenMain =()=>{

    const [pageToShow,setPageToShow] = useState(0)
    const navigate = useNavigate()



    const getPage=()=>{

        if(pageToShow){
            switch (pageToShow) {
                case 1:
                    return <CompanyForm/>
                case 2:
                    return <PersonalForm/>
                default:
                    return <></>

            }
        }
    }

    const handlePageChange=(page)=>{
        navigate(`/${page}`)
    }

    return(
        <>
              <div className={'parallax'} style={{backgroundImage:`url(${seo4}`}}>
                  <div className={'container'}>

                      <div style={{paddingTop:"10%"}} className={'card-holder'}>
                          <div className={'card bg-news'}>
                             <div className={'row'}>
                                 <div className={'col'}>
                                     <div className={'card-text-lg'}> Welcome to KeyGen </div><br/>


                                 </div>
                                 <div style={{paddingLeft:'25%'}} className={'col display-none'}>
                                     <img className={'img-fluid'} src={logo} alt={'logo'}/>
                                 </div>
                             </div>


                          </div>
                      </div>
                  </div>
              </div>
            <div style={{marginRight:'5%', marginLeft:'5%', marginTop:'4vh', backgroundColor:'#EEEEEE'}}>
                <div className={'row'}>
                    <div className={'col display-none'}>
                        <img className={'img-fluid'} src={seo2} alt={'seo'}/>
                    </div>
                    <div style={{paddingTop:'5%', paddingRight:'2%'}} className={'col'}>
                        <div className={'container'}>
                            <strong>Welcome to KeyGen. We are a small website that offers a Free SEO KeyWord Generator.</strong> <br/><br/>
                            Are you in need of some great keywords by company name?<br/><br/> Are you in need of some great keywords based off the services your business offers?<br/><br/>
                            Are you a fellow small website that just wants to be seen in google searches?<br/><br/>
                            Look no further, you have found a Free, no sign up, Keyword Generator. Start Below!
                        </div>


                    </div>
                </div>
            </div>

            <div className={'container'}>
                <div style={{textAlign: 'center', marginTop: '10vh', marginBottom: '2vh'}} className={'row'}>
                    <div  className={'container-lg'}>
                        <div className={'row'}>
                            <div  className={'col'}>

                                    <div className={'card-text-lg'}>  A little about Us </div><br/>

                                    <div className={'container'} style={{textAlign: 'center'}}>



                            Welcome to Keyword SEO Generator! We are a team of SEO experts who have come together to create a
                                powerful tool to help businesses and individuals improve their search engine optimization efforts.


                        <br/></div>
                                <div style={{marginTop:'2vh'}}>
                                    <button onClick={()=>handlePageChange('about-us')} className={"btn btn-primary"}>Read More</button>
                                </div>

                            </div>
                            <div style={{maxHeight:'100%'}} className={'col display-none'}>
                                <br/>
                                <img className={'img-fluid'} src={seo1} alt={'seo'}/>
                            </div>
                        </div>
                    </div>

                </div>
            </div>






            {/*<div style={{textAlign: 'center', marginTop: '5vh', marginBottom: '5vh'}} className={'row'}>*/}
            {/*    <Ad/>*/}
            {/*</div>*/}
                <div className={'container'}>

                    <div style={{textAlign: 'center',marginTop: '5%',  marginBottom: '5%', backgroundColor: 'lightblue'}} className={'form-container'}>

                        <div className={'card-text-lg'}>
                            Are you a Business or just a Personal Website? <br/>

                        </div>
                        <div> <spa>Click either button below to start</spa></div>

                        <div style={{marginTop: '10%', paddingBottom: '5%'}} className={'row'}>
                            <div className={'col'}>
                                <Tooltip title="If you're a business, click here">
                                    <button onClick={()=>setPageToShow(1)} className={'btn btn-primary'}>Business</button>
                                </Tooltip>
                            </div>

                            <div className={'col'}>
                                <Tooltip title="Personal Website?  click here">
                                    <button onClick={()=>setPageToShow(2)} className={'btn btn-primary'}>Personal</button>
                                </Tooltip>
                            </div>

                        </div>
                        <div style={{textAlign: 'center', marginTop: '5%'}}  className={'row'}>
                            <div className={'container'}>
                                {getPage()}
                            </div>
                        </div>

                    </div>

                </div>
            <div style={{backgroundImage:`url(${seo5})`}} className={'parallax'}>
                <div className={'container'}>
                    <div style={{textAlign: 'center', marginTop: '5vh', paddingTop: '10%'}} className={'row'}>
                        <div className={'card bg-blue'}>
                            <div className={'card-text-lg'}>  Check out our Blog Posts about SEO </div><br/>


                            <div className={'col'}><button onClick={()=>handlePageChange('blog')} className={"btn btn-primary"}>Read More</button> </div>
                        </div>

                    </div>
                </div>
            </div>


            <div style={{textAlign: 'center', marginTop: '5vh', marginBottom: '1%'}} className={'row'}>
                {/*<Ad/>*/}
            </div>












        </>
    )


}
export default KeywordGenMain
import React from 'react';
import { Button, message, Space } from 'antd';
import { Empty } from 'antd';
const SearchResults =(props)=>{
    const result = props.results;
    const [messageApi, contextHolder] = message.useMessage();
    const success = () => {
        messageApi.open({
            type: 'success',
            content: 'Copied to Clipboard',
        });
    };

    const copyMessage =(item)=>{
        navigator.clipboard.writeText(item);
        success();
    }


    return(
        <div className={'container'}>
            {contextHolder}
            <table className={'table table-hover'}>
                <thead>
                <tr>
                    <th style={{textAlign: 'center'}} scope={'col'}>Keyword</th>
                    <th  style={{textAlign: 'center'}} scope={'col'}>Action</th>
                </tr>
                </thead>
                <tbody>
                {result?
                    result.map(item=>(
                        <tr>
                            <td>{item}</td>
                            <td><button onClick={() =>  copyMessage(item)  } className={'btn btn-primary'}>Copy</button></td>
                            {/*<Button type="primary"  shape="round" onClick={() => success()} >copy</Button>*/}
                        </tr>
                    ))
                    :<tr><td colSpan={3}><Empty /></td></tr>
                }

                </tbody>
            </table>

        </div>
    )


}
export default SearchResults;
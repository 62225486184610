import React , {useEffect, useState}from 'react';

import blog from '../../json/blog1.json'
import {Card,Avatar} from "antd";

import {useNavigate} from "react-router-dom";
import Ad from "../ad/Ad";
import seo6 from "../../img/s-e-o.jpg";
import logo from "../../img/KeyGenLogo.png";
import support from "../../img/support.jpg";
const { Meta } = Card
const BlogMain = () => {
    const navigate = useNavigate();
    const [json, setJson] = useState(null)



    useEffect(()=>{
        if(!json){
            setJson(blog)
        }
    },[])

    const handlePageChange=(id)=>{
       navigate(`/blog/${id}`)
    }

    return(

         <>


             <div className={'parallax'} style={{backgroundImage:`url(${seo6}`}}>
                 <div className={'container '}>

                     <div style={{paddingTop:"10%"}} className={'card-holder'}>
                         <div className={'card bg-gold'}>
                             <div className={'row'}>
                                 <div className={'col'}>
                                     <div style={{color: "black", textAlign:'center'}} className={'card-text-lg'}>
                                        Welcome to our Blog <br/>
                                         <span>Check out some of our blog posts!</span><br/>
                                     </div><br/>

                                 </div>


                             </div>



                         </div>
                     </div>
                 </div>
             </div>
             <div className={'container'}>
                 <div style={{paddingTop: '5%'}} className={'row center-col'}>
                 {json?
                     json.map(blog=>(
                         blog.blogs.map(item=>(

                                 <div  className={'col '}>
                                     <Card style={{width: 300, justifyContent: 'center'}} cover={<img alt="example" src={item.url}/>
                                         }
                                         actions={[
                                             <button onClick={()=>handlePageChange(item.id)} className={"btn btn-primary"}>Read</button>,
                                         ]}>
                                         <Meta
                                             avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                                             title={item.prompt}
                                             description={`${(item.post).substring(0,25)}...`}/>
                                     </Card>
                                 </div>

                         ))
                     )):'Having issue loading blogs'
                 }
                 </div>

             </div>
         </>

    )


}
export default BlogMain;


import React, {useEffect, useState} from 'react';
import {Configuration, OpenAIApi} from 'openai'
import {config} from "../../openAIConfig/config";
import { Button, Space } from 'antd';
import SearchResults from "./SearchResults";

const CompanyForm =()=>{
    const [companyType, setCompanyType] =useState('')
    const [city, setCity]= useState('')
    const [state, setState] = useState('');
    const [companyName, setCompanyName]  = useState('')
    const [serviceOffered, setServiceOffered] = useState('')
    const [keywords, setKeywords] = useState(null)
    const [loading,setLoading] = useState(false)



    const configuration = new Configuration({
        apiKey: config.apikey,
    });
    const openai = new OpenAIApi(configuration);

    const responseParser =(res)=>{
        let arr = res.split('\n')
        arr.splice(0,2);
        return arr;

    }


    const getKeywords = async (event) => {
        setLoading(true)
        event.preventDefault();
        let prompt;
        if(companyName!==''){
            prompt= `Best Google Seo Keywords for ${companyName} in ${city}, ${state}`
        }else{
            prompt= `Best Google Seo Keywords for ${serviceOffered} store in ${city}, ${state}`
        }



        let response = await openai.createCompletion({
            model: "text-davinci-003",
            prompt: prompt,
            temperature: 0.7,
            max_tokens: 256,
            top_p: 1,
            frequency_penalty: 0,
            presence_penalty: 0,
        });


       if(response){
           let res =responseParser(response.data.choices[0].text)
           console.log(res)
           setKeywords(res)
           setLoading(false)
        }


    }




    return(
        <div className={'container search-form'}>


                       <div style={{marginBottom:'5%'}} className={'col'}>
                           <label>Company Name or Service</label>
                           <select onChange={(e)=>setCompanyType(e.target.value)} value={companyType} className={'form-control'} required>
                               <option>choose...</option>
                               <option value={"Company Name"}>Company Name</option>
                               <option value={"Service offered"}>Service offered</option>
                           </select>
                       </div>
            <div style={{marginBottom:'5%'}} className={'col'}>
                {companyType==='Company Name'?

                    <>
                        <label>Company Name</label>
                        <input className={'form-control'} value={companyName} onChange={(e)=>setCompanyName(e.target.value)} required={companyType === 'Company Name'}/>
                    </>
                    :''
                }
                {companyType==='Service offered'?
                    <>
                        <label>Service Offered</label>
                        <input className={'form-control'} value={serviceOffered} onChange={(e)=>setServiceOffered(e.target.value)} required={companyType === 'Service offered'}/>
                    </>
                    :''
                }
            </div>

                       <div style={{marginBottom:'5%'}} className={'col'}>
                           <label>State</label>
                           <select className={'form-control'} onChange={(e)=>setState(e.target.value)} required>
                               <option> choose.... </option>
                               {config.states.map(state=>(
                                   <option value={state.code}>{state.name}</option>
                               ))}
                           </select>
                       </div>
                       <div style={{marginBottom:'5%'}}className={'col'}>
                           <label>City</label>
                           <input className={'form-control'} value={city} onChange={(e)=>setCity(e.target.value)} required/>
                       </div>







                       <div  style={{paddingTop: '5%'}} className={'row'}>
                           <div className={'col'}>
                               <Button type="primary" loading={loading} onClick={(e)=>(getKeywords(e))}>
                                   Get Keywords!
                               </Button>
                           </div>

                       </div>




            <div style={{marginTop:'1%'}} className={'row'}>
                {<SearchResults results={keywords? keywords: null}/>}
            </div>


        </div>
    )



}
export default CompanyForm;
import React, {useRef, useState} from 'react';
import { API } from 'aws-amplify'
import { createEmail } from '../../graphql/mutations'
import support from "../../img/support.jpg";
import logo from "../../img/KeyGenLogo.png";
import {useNavigate} from "react-router-dom";
import {Button} from "antd";

const Contact=()=>{
    const [name,setName] = useState(null)
    const [email, setEmail] = useState(null)
    const [body, setBody] = useState(null)
    const [err, setErr] = useState(false)
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState(null)
    const [err2, setErr2] = useState(null)
    const navigate=useNavigate()

    const sendEmail =async () => {
        setErr(false)
        setErr2(null)
        setMessage(null)
        setLoading(true)
        if (!name || !email || !body) {
            setErr(true)
            setLoading(false)
        } else {
            let newEmail = await sendAPi();
            console.log(newEmail)
            if (newEmail.errors) {
                setErr2('Email does not exist')
                setLoading(false)
            } else {
                setLoading(false)
                setEmail('')
                setName('')
                setBody('')
                setMessage('Message sent!')
            }

        }
    }

    const sendAPi =async ()=>{
        try{
            let tmp =  await API.graphql({
                query: createEmail,
                variables: {
                    input: {
                        name,
                        email,
                        body
                    },
                },
            })

            return tmp
        }
        catch (e) {
            return e
        }
    }


    return(
        <div  className={'contact-page'}>

            <div className={'parallax'} style={{backgroundImage:`url(${support}`}}>
                <div className={'container '}>

                    <div style={{paddingTop:"10%"}} className={'card-holder'}>
                        <div className={'card bg-contact'}>
                            <div className={'row'}>
                                <div className={'col'}>
                                    <div style={{color: "white", textAlign:'center'}} className={'card-text-lg'}> Thanks for Visiting! </div><br/>


                                </div>


                            </div>
                            <div style={{paddingTop:'5%', paddingBottom:'5%'}} className={'row'}>
                                <div style={{textAlign:'center'}} className={'col'}>
                                    <button onClick={()=>navigate(`/`)} className={'btn btn-success'}>Back To Home</button>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>

         <div style={{backgroundColor:'#EEEEEE', height:'100%'}} className={'contact-page'} >
             <div style={{paddingTop: '5%', paddingLeft:'2%'}} className={'row'}>
                 <div className={'col'}>
                     <h1>Thank you so much For visiting KeyGen!</h1> <br/>
                      <strong>If you have any issues, questions, or concerns, use the Contact Us form on this page.<br/> <br/>
                      Our Team will reach out to you as soon as we can. <br/> <br/>
                          Thanks again for visting our website!
                      </strong>
                 </div>
                 <div className={'col'}>
                     <div className={'container-sm'}>
                         <div style={{marginBottom:'5%'}} className={'contact'}>
                             <div className={'row'}>
                                 <div style={{textAlign:'center', marginBottom:'2%', marginTop:'2%'}} className={'col'}>
                                     <h1>Contact Us</h1>
                                 </div>
                             </div>
                             {err?
                                 <div className={'row'}>
                                    <span style={{color: 'red', marginBottom:'2%'}}>ALL fields are required</span>
                                 </div>:''
                             }
                             {message?
                                 <div className={'row'}>
                                     <span style={{color: 'blue', marginBottom:'2%'}}>Message Sent!</span>
                                 </div>:''
                             }
                             {err2?
                                 <div className={'row'}>
                                     <span style={{color: 'red', marginBottom:'2%'}}>{err2}</span>
                                 </div>:''
                             }
                             <div className={'row'}>
                                 <div style={{marginBottom:'2%'}} className={'col'}>
                                     <input style={err && !name? {boxShadow:' 0 0 9px red'}:null} value={!name? '': name} placeholder={'Name *'} onChange={(e)=>setName(e.target.value)} className={'form-control'} required/>
                                 </div>
                             </div>
                             <div className={'row'}>
                                 <div style={{marginBottom:'2%'}} className={'col'}>
                                     <input style={err && !email? {boxShadow:' 0 0 9px red'}:null} value={!email? '': email} placeholder={'Email *'} onChange={(e)=>setEmail(e.target.value)} className={'form-control'} required/>
                                 </div>
                             </div>
                             <div className={'row'}>
                                 <div style={{marginBottom:'2%'}} className={'col'}>
                                     <textarea style={err && !body? {boxShadow:' 0 0 9px red'}:null} value={!body? '': body} placeholder={'Message *'} onChange={(e)=>setBody(e.target.value)} className={'form-control'} required/>
                                 </div>
                             </div>

                             <div style={{textAlign:'center'}} className={'row '}>
                                 <div style={{ paddingBottom:'5%'}} className={'col'}>
                                     <Button style={{width:'50%'}} type="primary" loading={loading} onClick={()=>{setErr(false); sendEmail()}}>
                                         Send
                                     </Button>
                                     {/*<button style={{width:'50%'}} onClick={()=>sendEmail()} className={'btn btn-primary'}>Send</button>*/}
                                 </div>
                             </div>
                         </div>
                     </div>

                 </div>
             </div>
         </div>

        </div>
    )


}
export default Contact;